<div class="text-primary md:mt-12 px-5">
  <form [formGroup]="businessIdentificationForm">
    <div formGroupName="businessIdentificationForm">

      <!-- Discrimination Experience -->
      <div class="mb-4">
        <div class="text-base mb-3 lg:text-lg font-medium">Discrimination in Business <sup>*</sup></div>
        <div class="tracking-wider mb-3">Please confirm whether you consider yourself to be disadvantaged in your business,
           in the qualifying country, as a result of your visible racial/ethnic difference.</div>
        <mat-radio-group aria-label="Select an option" formControlName="facing_disadvantage">
          <mat-radio-button [value]="true">Yes</mat-radio-button>
          <mat-radio-button [value]="false">No</mat-radio-button>
        </mat-radio-group>
      </div>

      <!--Name of the business-->
      <div class="text-base mb-3 lg:text-lg font-medium">
        Name of the business <sup>*</sup>
      </div>
      <mat-form-field class="w-full mb-3" appearance="outline">
        <input matInput formControlName="business_name" />
      </mat-form-field>

      <!-- Different Trading Name -->
      <div class="flex justify-between flex-col lg:flex-row">
        <div class="w-full lg:w-[48%]">
          <div class="text-base mb-3 lg:text-lg font-medium">
            Does the business have a different trading name? <sup>*</sup>
          </div>
          <mat-radio-group
            aria-label="Select an option"
            formControlName="has_different_trading_name"
          >
            <mat-radio-button [value]="true">Yes</mat-radio-button>
            <mat-radio-button [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="w-full lg:w-[48%]">
          <div class="text-base mb-3 lg:text-lg font-medium">
            If yes, please provide the name
          </div>
          <mat-form-field class="w-full mb-3" appearance="outline">
            <input matInput formControlName="different_trading_name" />
          </mat-form-field>
        </div>
      </div>

      <!-- Country of Business Registration -->
      <div class="flex items-center justify-between flex-col lg:flex-row">
        <div class="w-full lg:w-[48%]">
          <div class="text-base mb-3 lg:text-lg font-medium">
            Country of Business Registration <sup>*</sup>
          </div>
          <mat-form-field appearance="outline" class="w-full mb-3">
            <mat-select formControlName="country_of_business_registration">
              @for (country of countryOfOrigin; track country) {
              <mat-option [value]="country.value">{{ country.key }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Business Registration Number -->
        <div class="w-full lg:w-[48%]">
          <div class="text-base mb-3 lg:text-lg font-medium">
            Business registration number <sup>*</sup>
          </div>
          <mat-form-field class="w-full mb-3" appearance="outline">
            <input matInput formControlName="business_registration_number" />
          </mat-form-field>
        </div>
      </div>

      <!-- Business Type -->
      <div class="flex items-center justify-between flex-col lg:flex-row mb-3">
        <div class="w-full lg:w-[48%]">
          <div class="text-base mb-3 lg:text-lg font-medium">
            Business type <sup>*</sup>
          </div>
          <mat-form-field appearance="outline" class="w-full">
            <mat-select formControlName="business_type">
              @for (businessType of filteredBusinessType; track businessType) {
              <mat-option [value]="businessType.key" [disabled]="true">{{
                businessType.value
              }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>

        <!-- VAT Number -->
        <div class="w-full lg:w-[48%]">
          <div class="text-base mb-3 lg:text-lg font-medium">
            Please provide your VAT number
          </div>
          <mat-form-field class="w-full" appearance="outline">
            <input matInput formControlName="vat_number" />
          </mat-form-field>
        </div>
      </div>

      <!-- Address 1 -->
      <div class="text-base mb-3 lg:text-lg font-medium">
        Organisation Address Line 1
      </div>
      <mat-form-field class="w-full mb-3" appearance="outline">
        <input matInput formControlName="address_line1" />
      </mat-form-field>

      <!--Organisation Address Line 2 and 3-->
      <div class="flex items-center justify-between flex-col lg:flex-row">
        <div class="w-full lg:w-[48%]">
          <div class="text-base mb-3 lg:text-lg font-medium">
            Organisation Address Line 2 <sup>*</sup>
          </div>
          <mat-form-field class="w-full mb-3" appearance="outline">
            <input matInput formControlName="address_line2" />
          </mat-form-field>
        </div>
        <div class="w-full lg:w-[48%]">
          <div class="text-base mb-3 lg:text-lg font-medium">
            Organisation Address Line 3
          </div>
          <mat-form-field class="w-full mb-3" appearance="outline">
            <input matInput formControlName="address_line3" />
          </mat-form-field>
        </div>
      </div>


      <!-- Post Code -->
      <div class="flex items-center justify-between flex-col lg:flex-row mb-3">
        <div class="w-full lg:w-[48%]">
          <div class="text-base mb-3 lg:text-lg font-medium">
            Post Code <sup>*</sup>
          </div>
          <mat-form-field class="w-full" appearance="outline">
            <input matInput formControlName="postal_code" />
          </mat-form-field>
        </div>
        <div class="w-full lg:w-[48%]">
          <div class="text-base mb-3 lg:text-lg font-medium">
            Town/City <sup>*</sup>
          </div>
          <mat-form-field class="w-full" appearance="outline">
            <input matInput formControlName="city" />
          </mat-form-field>
        </div>
      </div>

      <!-- Webiste Url -->
      <div class="flex justify-between flex-col lg:flex-row">
        <div class="w-full lg:w-[48%]">
          <div class="text-base mb-3 lg:text-lg font-medium">
            Does your business have a website? <sup>*</sup>
          </div>
          <mat-radio-group
            aria-label="Select an option"
            formControlName="has_business_website"
          >
            <mat-radio-button [value]="true">Yes</mat-radio-button>
            <mat-radio-button [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="w-full lg:w-[48%]">
          <div class="text-base mb-3 lg:text-lg font-medium">
            If yes, what’s the URL?
          </div>
          <mat-form-field class="w-full" appearance="outline">
            <input matInput formControlName="website_url" />
          </mat-form-field>
        </div>
      </div>

      <!-- Social Media Handles -->
      <div class="flex justify-between flex-col lg:flex-row">
        <div class="w-full lg:w-[48%]">
          <div class="text-base mb-3 lg:text-lg font-medium">
            Mention your LinkedIn handle
          </div>
          <mat-form-field class="w-full" appearance="outline">
            <input matInput formControlName="linkedin_handle" />
          </mat-form-field>
        </div>
        <div class="w-full lg:w-[48%]">
          <div class="text-base mb-3 lg:text-lg font-medium">
            Other social media handles
          </div>
          <mat-form-field appearance="outline" class="w-full">
            <input matInput formControlName="other_handles" />
          </mat-form-field>
        </div>
      </div>
      <!----hear about us---->
      <div class="flex justify-between flex-col lg:flex-row">
        <div class="w-full lg:w-[48%]">
          <div class="text-base mb-3 lg:text-lg font-medium">
            How did you hear about us?
          </div>
          <mat-form-field appearance="outline" class="w-full">
            <mat-select
            formControlName="how_did_you_hear_about_us"
          >
            <!-- @for (options of howDidYouHearAboutUs; track howDidYouHearAboutUs) {
            <mat-option [value]="options.key">{{ options.value }}</mat-option>
            } -->
          </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="flex justify-between md:justify-end">
      <!-- <button mat-raised-button color="primary" class="mr-4 !rounded-[50px]">Save as draft</button> -->
      <button mat-button matStepperNext class="!rounded-[50px] next-button">
        <div class="flex items-center pl-3">
          Next step<mat-icon>chevron_right</mat-icon>
        </div>
      </button>
    </div>
  </form>
</div>
