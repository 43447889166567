import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MaterialModule } from './material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NonNumericDirective } from './directives/non-numeric.directive';
import { RegNumberDirective } from './directives/reg-number.directive';
import { PostalCodeDirective } from './directives/postal-code.directive';
import { VatNumberDirective } from './directives/vat-number.directive';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDateFormats } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { RestrictDateDirective } from './directives/restrictdate.directive';
import { NumericDirective } from './directives/numeric.directive';
import { EuroRestrictionDirective } from './directives/euro.directive';
import { ToastrModule } from 'ngx-toastr';
import { NumberValidationDirective } from './directives/number.directive';
import { HttpClientModule } from '@angular/common/http';
import { AngularFireFunctionsModule } from '@angular/fire/compat/functions';
import { ViewCertificationComponent } from './components/view-certification/view-certification.component';
import { ViewBusinessIdentificationComponent } from './components/view-business-identification/view-business-identification.component';
import { ViewBusinessProfileComponent } from './components/view-business-profile/view-business-profile.component';
import { ViewOwnershipComponent } from './components/view-ownership/view-ownership.component';
import { ViewCertificationAdminComponent } from './components/view-certification-admin/view-certification-admin.component';
import { ViewDocumentComponent } from './components/view-document/view-docuement.component';
import { DeleteConfirmationComponent } from './components/delete-confirmation/delete-confirmation.component';
import { UploadProgressOverlayComponent } from './components/upload-progress-overlay/upload-progress-overlay.component';
import { CarouselModule } from 'ngx-owl-carousel-o';

@NgModule({
  declarations: [NonNumericDirective, RegNumberDirective, PostalCodeDirective, VatNumberDirective, RestrictDateDirective,
    NumericDirective, EuroRestrictionDirective, NumberValidationDirective, ViewCertificationComponent, ViewBusinessIdentificationComponent,
     ViewBusinessProfileComponent, ViewOwnershipComponent, ViewDocumentComponent, ViewCertificationAdminComponent, DeleteConfirmationComponent, UploadProgressOverlayComponent ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    AngularFireFunctionsModule,
    CarouselModule
  ],
  exports: [
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NonNumericDirective,
    RegNumberDirective,
    PostalCodeDirective,
    VatNumberDirective,
    RestrictDateDirective,
    NumericDirective,
    EuroRestrictionDirective,
    ToastrModule,
    NumberValidationDirective,
    AngularFireFunctionsModule,
    ViewCertificationComponent,
    ViewBusinessIdentificationComponent,
    ViewBusinessProfileComponent,
    ViewOwnershipComponent,
    ViewDocumentComponent,
    ViewCertificationAdminComponent,
    DeleteConfirmationComponent,
    UploadProgressOverlayComponent,
    CarouselModule
  ],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: {
        useUtc: true
      }
    },
    DatePipe
  ]
})
export class SharedModule { }
